import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa';
// import { FiTv } from 'react-icons/fi';
import { NavIcon } from '../Navbar/NavbarStyles';
import {
	FooterContainer,
	FooterSubscription,
	FooterSubHeading,
	SocialMedia,
	SocialMediaWrap,
	SocialLogo,
	// SocialIcon,
	WebsiteRights,
	SocialIcons,
	SocialIconLink,
} from './FooterStyles';

const Footer = () => {
	const dateNow = new Date().getFullYear()
	return (
		<FooterContainer>
			<FooterSubscription>
				<SocialLogo to="/">
				{/* <NavIcon style={{width: 300, marginTop: 40, left: 150, height: 150,}} src="./images/img.PNG" alt="" /> */}
				{/* <FiTv style={{marginRight: 10, marginBottom: 5}} color="red" size="2.3rem" /> */}
					{/* <SocialIcon src="./images/logo.svg" /> */}
					<span style={{fontWeight: 'bold'}}>UABCSP</span>
				</SocialLogo>
				<FooterSubHeading>THE UNIVERSAL ASSEMBLY OF BCS PRIESTHOOD</FooterSubHeading>
			</FooterSubscription>

			<SocialMedia>
				<SocialMediaWrap>
					<WebsiteRights>© Copyright {dateNow} GB-UABCSP All Rights Reserved</WebsiteRights>
					<SocialIcons>
						<SocialIconLink href="/" target="blank" aria-label="Facebook">
							<FaFacebook />
						</SocialIconLink>
						<SocialIconLink href="/" target="blank" aria-label="Instagram">
							<FaInstagram />
						</SocialIconLink>
						<SocialIconLink href="/" target="blank" aria-label="Twitter">
							<FaTwitter />
						</SocialIconLink>
						<SocialIconLink href="/" target="blank" aria-label="Youtube">
							<FaYoutube />
						</SocialIconLink>
					</SocialIcons>
				</SocialMediaWrap>
			</SocialMedia>
		</FooterContainer>
	);
};

export default Footer;
