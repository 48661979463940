export const heroOne = {
	id: 'projects',
	topLine: {
		text: 'Enjoy more content on the blog',
	},
	headline: 'All Priest Database Registration Ongoing',
	description: 'Thank You Father',
	buttonLabel: 'Ask Questions',
	imgStart: 'start',
	img: './images/img.PNG',
	backgroundColor: 'linear-gradient(	150deg,#fff -20%,#ff9999)',
	start: 'true',
};

export const heroTwo = {
	reverse: true,
	topLine: {
		text: 'Enjoy more content on the blog',
	},
	headline: 'Priesthood Workshop Coming Up April 2023',
	description: 'Thank You Father',
	buttonLabel: 'Ask Questions',
	linkTo: '/more',
	imgStart: 'start',
	img: './images/img.PNG',
	// img: './images/content/landing-page.svg',
	backgroundColor: 'linear-gradient(	140deg	,#ffaf73 30%,#fffecc 120%)',

	start: 'true',
};

export const heroThree = {
	topLine: {
		text: 'Enjoy more content on the blog',
	},
	headline: "New Posting",
	description: 'Thank You Father',
	buttonLabel: 'Ask Questions',
	linkTo: '/download',
	imgStart: '',
	backgroundColor: 'linear-gradient(104deg, rgba(151,147,218,1) 0%, rgba(183,137,205,1) 100%)',
	// img: './images/content/lost.svg',
	img: './images/img.PNG',
	start: 'true',
};

export const heroFour = {
	reverse: true,
	topLine: {
		text: 'Enjoy more content on the blog',
	},
	headline: 'Priesthood Workshop Coming Up April 2023',
	description: 'Thank You Father',
	buttonLabel: 'Ask Questions',
	linkTo: '/more',
	imgStart: '',
	backgroundColor: 'linear-gradient(225deg,#0abac2,#b2de94)',
	// img: './images/content/ecommerce.svg',
	img: './images/img.PNG',
	start: 'true',
};
