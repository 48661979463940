export const navbarData = [
	{
		to: 'about',
		text: 'Bodies',
	},
	{
		to: 'projects',
		text: 'News',
	},
	{
		to: 'clients',
		text: 'Contact Us',
	},
];
