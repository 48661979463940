import React from 'react';
import { Container } from '../../globalStyles';
import {
	ClientSection,
	ClientColumn,
	ClientText,
	ClientTitle,
	ClientRow,
	ClientWrapper,
	ClientImage,
	ClientTextWrapper,
} from './ClientsStyles';
import { clientsData } from '../../data/ClientsData';

const Clients = () => {
	return (
		<ClientSection id="clients">
			<Container>
				<ClientTextWrapper>
					<ClientTitle>Contacts</ClientTitle>
					<ClientText>Contact us today for any inquries and report about priesthood.</ClientText>
				</ClientTextWrapper>

				<ClientRow>
					{clientsData.map((clients, clientsIndex) => (
						<ClientColumn key={clientsIndex}>
							{clients.map((el, index) => (
								<ClientWrapper key={index}>
									<h4 style={{textAlign: 'center'}}>
										{el.name}

									</h4>
									{/* <ClientImage src={`./images/companies/${el.name}.svg`} /> */}
								</ClientWrapper>
							))}
						</ClientColumn>
					))}
				</ClientRow>
			</Container>
		</ClientSection>
	);
};

export default Clients;
