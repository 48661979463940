// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {nanoid} from 'nanoid'
import {getDownloadURL, ref,uploadBytes} from 'firebase/storage'
import { getStorage } from "firebase/storage";
import {getFirestore, collectionGroup, } from 'firebase/firestore'
// import { initializeFirestore } from "firebase/firestore";




const firebaseConfig = {
    apiKey: "AIzaSyCDisC18DyFK0oAWcFaorrIEaBJThV2-o0",
    authDomain: "gb-uabcsp.firebaseapp.com",
    projectId: "gb-uabcsp",
    storageBucket: "gb-uabcsp.appspot.com",
    messagingSenderId: "872035227247",
    appId: "1:872035227247:web:7dcddbfddb5112941c9f9a",
    measurementId: "G-3BVTGGJFFF"
  };


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db=getFirestore(app)

// export const storage = getStorage(app);
// export const db = initializeFirestore(app, {
//   experimentalForceLongPolling: true,
// });

export function SignIn(email,password){
  return signInWithEmailAndPassword(auth,email,password)
}

export function SignUp(email,password){
  return createUserWithEmailAndPassword(auth,email,password)
}

export async function UploadImage(uri,path,fname){
    const storage=getStorage(app)
      // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });

  const filename = fname ||nanoid()
  const imageRef = ref(storage, `${path}/${filename}.jpeg`)
  
  const snapshot=await uploadBytes(imageRef,blob,{
      contentType:'image/jpeg'
  })

  // We're done with the blob, close and release it
  blob.close();

  const url=await getDownloadURL(snapshot.ref)

  return {url,filename};
}

export async function UploadVideo(uri,path,fname){
    const storage=getStorage(app)
      // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });

  const filename = fname ||nanoid()
  const videoRef = ref(storage, `${path}/${filename}.mp4`)
  
  const snapshot=await uploadBytes(videoRef,blob,{
      contentType:'video/mp4'
  })

  // We're done with the blob, close and release it
  blob.close();

  const url=await getDownloadURL(snapshot.ref)

  return {url,filename};
}

export async function UploadAudio(uri,path,fname){
    const storage=getStorage(app)
      // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });

  const filename = fname ||nanoid()
  const audioRef = ref(storage, `${path}/${filename}.mp3`)
  
  const snapshot=await uploadBytes(audioRef,blob,{
      contentType:'audio/mp3'
  })

  // We're done with the blob, close and release it
  blob.close();

  const url=await getDownloadURL(snapshot.ref)

  return {url,filename};
}

// export async function UploadPost(uri,path,fname){
//   const storage=getStorage(app)
//     // https://github.com/expo/expo/issues/2402#issuecomment-443726662
// const blob = await new Promise((resolve, reject) => {
//   const xhr = new XMLHttpRequest();
//   xhr.onload = function () {
//     resolve(xhr.response);
//   };
//   xhr.onerror = function (e) {
//     console.log(e);
//     reject(new TypeError("Network request failed"));
//   };
//   xhr.responseType = "blob";
//   xhr.open("GET", uri, true);
//   xhr.send(null);
// });

// const filename = fname ||nanoid()
// const imageRef = ref(storage, `${path}/${filename}.jpeg`)

// const snapshot=await uploadBytes(imageRef,blob,{
//     contentType:'image/jpeg'
// })

// // We're done with the blob, close and release it
// blob.close();

// const url=await getDownloadURL(snapshot.ref)

// return {url,filename};
// }