import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
// import MainVideo from '../../assets/bbb.mp4'
import MainVideo from '../../assets/p.mp4'
import {
	HeroSection,
	Heading,
	HeroText,
	ButtonContainer,
	HeroButton,
	ImageCharacter,
	HeroImage,
	HeroContent,
	ButtonWrapper,
	CharacterContainer,
	HeroVideo,
} from './HeroStyles';
import { useInView } from 'react-intersection-observer';
import Modal from '../Modal/Modal';
// import { Carousel } from 'react-bootstrap';

// import image1 from '../../assets/images/1.jpg';
// import image2 from '../../assets/images/2.jpg';
// import image3 from '../../assets/images/3.jpg';

const Hero = () => {
	const [showModal, setShowModal] = useState(false);
	const dragConstraints = { top: 0, bottom: 0, right: 0, left: 0 };

	const toggleModal = () => {
		if (!showModal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'visible';
		}

		setShowModal(!showModal);
	};

	const variants = {
		hover: {
			y: 15,
			transition: {
				yoyo: Infinity,
				duration: 0.6,
			},
		},
	};
	const { ref, inView } = useInView({
		rootMargin: '-100px',
	});

	useEffect(() => {
		console.log(inView);
	}, [inView]);

	return (
		<>
		<div>
			<HeroSection id="hero">
				{/* <HeroImage className="pattern" src="./images/hero-pattern-bg-lg.png" /> */}
				<CharacterContainer>
					<ImageCharacter
						dragConstraints={dragConstraints}
						className="one"
						// src="./images/image 1.png"
					/>
					<ImageCharacter
						dragConstraints={dragConstraints}
						className="two"
						// src="./images/image 2.png"
					/>
					<ImageCharacter
						dragConstraints={dragConstraints}
						// className="two"
						// src="./images/im.PNG"
					/>
					<ImageCharacter
						variants={variants}
						whileHover="hover"
						drag
						dragConstraints={dragConstraints}
						className="three"
						// src="./images/image 3.png"
					/>
				</CharacterContainer>

				{/* <HeroVideo> */}

				      <div
          dangerouslySetInnerHTML={{
			  __html: `<video  style="
			  position: absolute;
			  width: 100%;
			  top: 0;
			  height: 100%;
			  object-fit: cover;
			  z-index: -1;" autoplay loop muted playsinline>
			  <source src=${MainVideo} type="video/mp4" />
			  Your browser does not support the video tag.
			  </video>`
			}}
			/>
			 {/* <video autoPlay loop muted id='video'  style={{position: 'absolute'}}>
                <source src={MainVideo} type='video/mp4'/>
            </video> */}
			{/* </HeroVideo> */}
				<HeroContent>
					<Heading> <span style={{color: 'red'}}>UNIVERSAL </span>
					<span style={{color: 'blue'}}>ASSEMBLY OF BCS </span>  
					<span style={{color: 'red'}}>PRIESTHOOD</span></Heading>
					<HeroText>
					Ye are a chosen generation, a royal priesthood, 
					an holy nation, a peculiar people; that ye should 
					shew forth the praises of him who hath called you 
					out of darkness into his marvellous light: 1st Peter 2:9					</HeroText>
					<ButtonContainer ref={ref}>
						<ButtonWrapper>
							<HeroButton onClick={toggleModal} className={inView ? '' : 'corner'}>
								{inView ? (
									<> Search</>
								) : (
									<FiSearch color="white" size="2.3rem" />
								)}
							</HeroButton>
						</ButtonWrapper>
					</ButtonContainer>
				</HeroContent>
			</HeroSection>
			</div>

			<Modal showModal={showModal} toggleModal={toggleModal} />
		</>
	);
};

export default Hero;
