import React, {useState, useEffect} from "react";
import './stylez.css';
import data from "./TemplateData.json";
import { db } from '../firebase';
import {   getDocs, addDoc, collection, onSnapshot, } from 'firebase/firestore';


function Users() {
  const [searchTerm, setSearchTerm] = useState("");
  const [Priests, setPriests]=useState([])
  async function getUsers(){
      const usersCol = collection(db,'Priests');
      const usersDet =  await getDocs(usersCol);
      const usersLIst = usersDet.docs.map(doc => doc.data());
      setPriests(usersLIst)
      // console.log(usersLIst)
      
  }
useEffect(() => { 
    getUsers()
  }, [Priests]);
  const [show, setShow] = useState(false)
  const [P, setP] = useState(false)
  return (
    <>
      <div className="templateContainer">
        <div className="searchInput_Container">
          <input id="searchInput" type="text"
          placeholder="Search here..." onChange={(event) => {
            setSearchTerm(event.target.value);
            if(event.target.value.length >2){
              setShow(true)
          }else if (event.target.value === null) {
            setP(true)
          }
          else{
            setShow(false)
            setP(false)
          }
          }} />
        </div>
        {show ? (
        <div className="template_Container">
          {
            Priests 
              .filter((val) => {
                if(searchTerm == ""){
                  return val;
                }else if(val.allName?.toLowerCase().includes(searchTerm.toLowerCase())){
                  return val;
                }
              })
              .map((val) => {
                return(
                  <div className="template" style={{borderRadius: 40}} key={val.id}>
                      <img style={{borderRadius: 150}} src={val.passport} alt="" />
                      <h3 style={{textAlign: 'center'}}>{val.allName}</h3>
                      <p style={{paddingBottom: 20, textAlign: 'center'}} className="price">{val.Station == "none" ? "Member" : "Posted To"} {val.Station == "none" ? null : val.Station}</p>
                      {/* <p className="price">${val.price}</p> */}
                  </div> 
                )
              })
          }
        </div> 
        ) 
        : 
        ( null )
        }
      </div>
    </>
  )
}

export default Users;
