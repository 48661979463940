import React, { useState } from 'react';
import { CgMenuRight } from 'react-icons/cg';
import { FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import {
	Nav,
	NavbarContainer,
	NavLogo,
	NavIcon,
	MobileIcon,
	NavMenu,
	NavLinks,
	NavItem,
} from './NavbarStyles';
import { navbarData } from '../../data/NavbarData';
// import { FiTv } from 'react-icons/fi';

const Navbar = () => {
	const [show, setShow] = useState(false);

	const scrollTo = (id) => {
		const element = document.getElementById(id);
		element.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const closeMobileMenu = (id) => {
		scrollTo(id);

		setShow(false);
	};

	return (
		<IconContext.Provider value={{ color: '#fff' }}>
			<Nav>
				<NavbarContainer>
					{/* <NavLogo to="/"> */}
					{/* <FiTv style={{marginRight: 10, marginBottom: 5}} color="red" size="2.3rem" /> */}
						{/* <NavIcon style={{width: 320, marginTop: 80, marginLeft: -90, height: 160,}} src="./images/img.PNG" alt="" /> */}
						<NavIcon src="./images/3.png" alt="" />
						{/* <NavIcon style={{width: 260, marginTop: 50, marginLeft: -50, height: 200,}} src="./images/b.PNG" alt="" /> */}
						{/* <NavIcon src="./images/logo.svg" alt="" style={{marginTop: 40}} /> */}
						{/* <NavIcon src="../assets/images/4.png" alt="" style={{marginTop: 40, width: 200, height: 300, borderRadius: 200}} /> */}
						 {/* <span style={{fontWeight: 'bold',marginTop: 40, marginLeft: 10}}>UABCSP</span> */}
					{/* </NavLogo> */}
					<MobileIcon style={{height: 60, right: -40, width: 60, borderRadius: 100, textAlign: 'center'}} onClick={() => setShow(!show)}>
						{show ? <FaTimes style={{fontSize: 30, color: '#333', marginTop: 14 }}/> : <CgMenuRight style={{fontSize: 30, color: '#333', marginTop: 14 }}/>}
					</MobileIcon>
					<NavMenu show={show}>
						{navbarData.map((el, index) => (
							<NavItem style={{marginTop: 30, marginRight: 30}} key={index}>
								<NavLinks style={{fontWeight: 'bold'}} to="/" onClick={() => closeMobileMenu(el.to)}>
									{el.text}
								</NavLinks>
							</NavItem>
						))}
					</NavMenu>
				</NavbarContainer>
			</Nav>
		</IconContext.Provider>
	);
};

export default Navbar;
