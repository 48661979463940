export const clientsData = [
	[{ name: 'HE Glory Ntuk Chairperson 08063333731'}],
	[{ name: 'CA Ikechukwu Nweze Board Sec 08034937765' }],
	[{ name: 'CA (Disciple) Nnanna Mong ICT/MEDIA 08034143558' }],
	[{ name: 'OFFICE EMAIL: guabcsp@gmail.com Office line for call only 09037242178' }],
	[{ name: 'UABCSP Official line for calling, Whatsapp, text message etc 09034165113' }],
];
// export const clientsData = [
// 	[{ name: '+23408141225457'}],
// 	[{ name: '+23408141225457' }, { name: '+23408141225457' }],
// 	[{ name: '+23408141225457' }, { name: '+23408141225457' }, { name: '+23408141225457' }],
// 	[{ name: '+23408141225457' }, { name: '+23408141225457' }],
// 	[{ name: '+23408141225457' }],
// ];
