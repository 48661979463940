import React from 'react';
import { BsPhone } from 'react-icons/bs';
import { GiConcentrationOrb } from 'react-icons/gi';
import { IoLogoXing } from 'react-icons/io';
import { HiCode } from 'react-icons/hi';
import { MdSlowMotionVideo } from 'react-icons/md';
import { FaPhotoVideo } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon size="3rem" color="#fefefe" />;

export const featuresData = [
	{
		name: '144,000 Virgins Body',
		description:
		"Royal Courtiers",
		// icon: iconStyle(BsPhone),
		imgClass: 'one',
	},
	{
		name: 'Christ Shepherds',
		description: 'The first heavenly order',
		// icon: iconStyle(FaPhotoVideo),
		imgClass: 'six',
	},
	{
		name: 'College Of Bishops',
		description: 'Comprising of Arch Bishops and Bishops',
		// icon: iconStyle(MdSlowMotionVideo),
		imgClass: 'five',
	},
	{
		name: 'Mansion Of Blessedness',
		description: 'Comprising of Eminences and Blessed Mothers',
		// icon: iconStyle(HiCode),
		imgClass: 'four', 
	},
	{
		name: 'Council Of Ambassadors',
		description: 'Christ Ambassadors',
		// icon: iconStyle(GiConcentrationOrb),
		imgClass: 'two',
	},
	{
		name: 'Divine Vanguards',
		description: 'The Ombudsmen',
		// icon: iconStyle(IoLogoXing),
		imgClass: 'three',
	},
	{
		name: 'True Christ Witnesses',
		description: 'First Fruit of the Kingdom',
		// icon: iconStyle(FaPhotoVideo),
		imgClass: 'seven',
	},
	{
		name: 'Senior Christ Servants',
		description: 'The true servants of Christ',
		// icon: iconStyle(FaPhotoVideo),
		imgClass: 'seven',
	},
	{
		name: 'Christ Practical Students',
		description: 'True Students',
		// icon: iconStyle(FaPhotoVideo),
		imgClass: 'seven',
	},
	{
		name: 'Christ Natural Preachers',
		description: 'True Preachers',
		// icon: iconStyle(FaPhotoVideo),
		imgClass: 'seven',
	},
	// {
	// 	name: '',
	// 	// description: 'Christ Practical Students',
	// 	// icon: iconStyle(FaPhotoVideo),
	// 	imgClass: 'seven',
	// },
	// {
	// 	name: '',
	// 	// description: 'Christ Practical Students',
	// 	// icon: iconStyle(FaPhotoVideo),
	// 	imgClass: 'seven',
	// },
];
